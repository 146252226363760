import React from "react";
import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import SEO from "../../components/SEO";
import Breadcrumbs from "../../components/Breadcrumbs";

function BlogIndexPage() {
  return (
    <Layout>
      <SEO
        title={"Blog | Sell Your Home For Cash Sacramento"}
        keywords={"sell your home"}
        description={"Latest posts: Blog on Sell Your Home For Cash Sacramento"}
      />

      {/* Breadcrumbs */}
      <div className="bg-white border-b border-gray-100">
        <nav className="flex container mx-auto px-4 py-3" aria-label="Breadcrumb">
          <Breadcrumbs links={[{ to: '/', label: 'Home' }, { to: '/blog', label: 'Blog' }]} />
        </nav>
      </div>

      {/* Blog Section */}
      <div className="relative bg-gray-50 pt-12 pb-16 px-4 sm:px-6 lg:pt-16 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative container mx-auto">
          <div className="text-center">
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Resources To Help You Sell Your House Fast

            </h1>
            <p className="mt-1 max-w-2xl mx-auto text-xl text-gray-500">
            Browse expert guides, tips, and tools to help you sell your house fast in Sacramento. Everything you need to navigate a quick and hassle-free home sale.
            </p>
          </div>
          
          {/* Blog Roll (should include images per post) */}
          <BlogRoll showImages={true} />
        </div>
      </div>
    </Layout>
  );
}

export default BlogIndexPage;
