import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
<div className="mt-12 mx-auto grid gap-6 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl px-4 sm:px-6 lg:px-8">
{posts &&
          posts.map(({ node: post }) => (
            <div className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden" key={post.id}>
              {post.frontmatter.featuredimage && post.frontmatter.featuredimage.childImageSharp && (
                <Img
                  fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                  alt={post.frontmatter.title}
                  className="h-48 w-full object-cover"
                />
              )}
              <div className="p-6 flex flex-col justify-between">
                <div>
                  <p className="text-sm text-gray-500">
                    <time dateTime={post.frontmatter.date}>{post.frontmatter.date}</time>
                  </p>
                  <Link to={post.fields.slug} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.frontmatter.title}</p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.excerpt.slice(0, 130)}...
                    </p>
                  </Link>
                </div>
                <div className="mt-6">
                  <Link
                    className="text-base font-semibold text-brand-600 hover:text-brand-500"
                    to={post.fields.slug}
                  >
                    Keep reading →
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
